import React, { useEffect, useState, useCallback } from "react";
import { Button, makeStyles, shorthands } from "@fluentui/react-components";
import { customTokens } from "../../utils/Theming";
import { Spinner } from '@fluentui/react/lib/Spinner';
import Ows from "../../utils/OwsHandler";
import Message from "../../blocks/Message";
import NoData from "../../blocks/NoData";
import PhoneField from "./field/PhoneField";
import DateField from "./field/DateField";
import CheckboxField from "./field/CheckboxField";
import TextareaField from "./field/TextareaField";
import MultiselectField from "./field/MultiselectField";
import SelectField from "./field/SelectField";
import TextField from "./field/TextField";
import { validateEmail, validateMandatory, validateMaxLength, validateLettersOnly, validateRegex, formatFirstLetterCapitalize, formatAllWordsFirstLetterCapitalize, formatLettersCapitalize } from "../../utils/Validation";
import { isPossiblePhoneNumber } from  'libphonenumber-js';
import { useDispatch } from "react-redux";
import { fetchCandidate } from "../candidate/candidate-slice";
import { fetchProcedure } from "../procedure/procedure-slice";


const SUPPORTED_FIELDS = ['Email', 'Textfield', 'PhoneNumber', 'Date', 'AcceptTerms', 'YesNo', 'Textarea', 'Select', 'Criteria'];

function validationToField(validation) {
    let state = '', message = '';
    if (validation === null) {
        state = 'none';
        message = '';
    } else if (typeof validation === 'string') {
        state = 'error';
        message = validation;
    } else {
        state = 'success';
        message = '';
    }

    return [state, message];
}

/**
    * Convert FreeInteraction response to state
    * @param {{form: any, answers: any}} response
    * @returns {{form: any, answers: any, validation: any}}
*/
function freeInteractionResponseToState(response) {
    const form  = {
        fields: {},
        answers: {},
        validation: {},
        sessionID: ''
    };
    for (let k in response.form.Fields) {
        const field = response.form.Fields[k];
        if (!SUPPORTED_FIELDS.includes(field.typeUi)) {
            continue;
        }
        form.fields[k] = field;
        form.validation[k] = null;
        const answer = response.answers[k];
        
        if (['YesNo', 'AcceptTerms'].includes(field.typeUi) && typeof answer === 'string' && answer !== '') {
            form.answers[k] = answer === '1';
            continue;
        }
        if (field.typeUi === 'Date' && typeof answer === 'string' && answer !== '') {
            const date = new Date(answer);
            if (date.toString() === 'Invalid Date') {
                continue;
            }
            form.answers[k] = date;
            continue;
        }
        if (typeof answer === 'object' && answer !== null) {
            form.answers[k] = Object.keys(answer) || [];
            continue;
        }
        form.answers[k] = response.answers[k] || null;
    }
    form.form = response.form;
    form.sessionID = response.sessionId;
   
    return form;
}

function freeInteractionStateToRequest(state) {
    const answers = {...state.answers};
    for (let k in answers) {
        const answer = answers[k];
        const field = state.fields[k];
        if (answer instanceof Date) {
            answers[k] = `${answer.getFullYear()}${String(answer.getMonth() + 1).padStart(2, '0')}${String(answer.getDate()).padStart(2, '0')}`;
        }
        if (field.typeUi === 'PhoneNumber') {
            const personPhonenumbersMap = {
                primary: 1,
                mobile: 2,
                work: 3,
                other: 4,
                home: 5,
                fax: 6,
                faxOther: 7
            };
            
            const phoneNumberModel = {
                DELETE: []
            };
            
            const wsField = field.wsField;
            const match = /\[([\S]+)=([\S]+)\]/g.exec(wsField);
            
            if (!match) {
                continue;
            }
            
            const phoneType = match[2];
            
            if (Object.hasOwn(personPhonenumbersMap, phoneType)) {
                const index = personPhonenumbersMap[phoneType];
            
                if (!answer) {
                    phoneNumberModel.DELETE.push(index);
                } else {
                    phoneNumberModel[index] = { phoneNumber: answer };
                }
            
                answers[k] = phoneNumberModel;
            }
        }
    }
    return answers;
}

// const useBaseClassName = makeResetStyles({
// });
const useStyles = makeStyles({
    message: {
        ...shorthands.margin(customTokens.spacingVerticalSNudge, 0, customTokens.spacingVerticalM)
    },
    fieldsBlock: {
        '& > *': {
            ...shorthands.margin(0, 0, customTokens.spacingHorizontalMNudge)
        }
    }
 });

 export default function FimForm({id, entity, ooid}) {
    const dispatch = useDispatch();
    // const baseClassName = useBaseClassName();
    const classes = useStyles();
    const [formFull, setFormFull] = useState(null);
    const [informMessage, setInformMessage] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const updateAnswer = useCallback((k, v) => {
        setFormFull(prevForm => {
            const newForm = {...prevForm};
            newForm.answers[k] = v;
            return newForm;
        });
    }, [setFormFull]);
    const sessionStart = useCallback(async (answerID) => {
        if (!answerID) {
            const {response}  = await Ows.handleRequest(
                'Otys.Services.FreeInteractionService.findFormLastAnswerSetForEntity',
                [id, entity, ooid]
            );
            answerID = response;
        }
        const {response} = await Ows.handleRequest(
            'Otys.Services.FreeInteractionService.sessionStart',
            [id, entity, ooid, +answerID]
        );
        setFormFull(freeInteractionResponseToState(response));
    }, [entity, id, ooid, setFormFull]);

    function validateForm() {
        function updateValidation(k, v) {
            setFormFull(prevForm => {
                const newForm = {...prevForm};
                newForm.validation[k] = v;
                return newForm;
            });
        
        }
        for (let key in formFull.answers) {
            const form = fieldsObj[key];
            if (form.Validation.mandatory && !validateMandatory(formFull.answers[key])) {
                updateValidation(key, 'This field is mandatory');
                return false;
            }
            if (form.Validation.email && !validateEmail(formFull.answers[key])) {
                updateValidation(key, 'Invalid email');
                return false;
            }
            if (form.Validation.maxLen && !validateMaxLength(formFull.answers[key], form.Validation.maxLen)) {
                updateValidation(key, 'Too long');
                return false;
            }
            if(form.Validation.regex && !validateRegex(formFull.answers[key], form.form.regexString)) {
                updateValidation(key, 'Invalid format');
                return false;
            }
            if(form.Validation.phone) {
                if (typeof formFull.answers[key] === 'string' && formFull.answers[key].trim().length > 0 && !isPossiblePhoneNumber(formFull.answers[key])) {
                    updateValidation(key, 'Invalid phone number');
                    return false;
                }
            }

            if(form.Validation.lettersOnly && !validateLettersOnly(formFull.answers[key])) {
                updateValidation(key, 'Only letters are allowed');
                return false;
            }
            if(form.Validation.firstLetterCapital) {
                updateAnswer(key, formatFirstLetterCapitalize(formFull.answers[key]));
            }
            if(form.Validation.allWordsCapital) {
                updateAnswer(key, formatAllWordsFirstLetterCapitalize(formFull.answers[key]));
            }
            if(form.Validation.allLettersCapital) {
                updateAnswer(key, formatLettersCapitalize(formFull.answers[key]));
            }
            updateValidation(key, null);
        }

        return true;
    }

    async function saveForm() {
        setInformMessage(null);
        setSubmitting(true);
        if (validateForm()) {
            try {
                const {response} = await Ows.handleRequest(
                    'Otys.Services.FreeInteractionService.sessionSubmit',
                    [sessionID, freeInteractionStateToRequest(formFull)]
                );
                if (!response) {
                    throw new Error('empty submit response');
                }
                const {response: commitResponse} = await Ows.handleRequest(
                    'Otys.Services.FreeInteractionService.sessionCommit',
                    [sessionID, {isPrivate: false, skipDossier: true}]
                );
                if (formFull.form.savePdfInDossier) {
                    await Ows.handleRequest(
                        'Otys.Services.PrintService.renderToFileAndSaveInDossier',
                        ['FreeInteraction', id, freeInteractionStateToRequest(formFull), 'pdf', commitResponse.entityId, ooid]
                    );
                }
                setInformMessage({message: 'Your form has been submitted', type: 'success'});
                await sessionStart(commitResponse.answerSetId);
                dispatch(fetchCandidate());
                dispatch(fetchProcedure());
            } catch (e) {
                console.error(e);
                setInformMessage({message: 'Something went wrong, please try again later', type: 'error'});
                return;
            }
        }
        setSubmitting(false);
    }

    useEffect(() => {
        setFormFull(null);
        (async () => {
            await sessionStart();
        })();
    }, [id, entity, ooid, sessionStart]);
    const sessionID = formFull?.sessionID;
    const fieldsObj = formFull?.fields || {};
    //const actionID = formFull?.form?.actionId;
    const fields = Object.values(fieldsObj).sort((a, b) => a.rank - b.rank);

    const onSubmit = async (e) => {
        e.preventDefault();
        await saveForm();
    };
    
    if (formFull === null && fields.length === 0) {
        return <Spinner label="I am definitely loading..." />;
    } else if (fields.length === 0) {
        return <NoData message="Unfortunatelly this IForm is empty" />;
    }

    const fieldsHtml = fields.map((field) => {
        const commonProps = {
            uid: field.uid,
            label: field.name,
            required: field.Validation.mandatory,
            validation: validationToField(formFull?.validation[field.uid]),
            value: formFull.answers[field.uid],
            onChange: updateAnswer
        };
        switch (field.typeUi) {
            default:
                return '';
            case 'Email': 
            case 'Textfield':
                const type = field.typeUi === 'Email' ? 'email' : 'text';
                return (
                    <TextField key={field.uid} {...commonProps} type={type} />);
            case 'PhoneNumber':
                return (
                    <PhoneField key={field.uid} {...commonProps} />
                );
            case 'Date':
                return (
                    <DateField key={field.uid} {...commonProps} />
                );
            case 'AcceptTerms':
            case 'YesNo':
                return (
                    <CheckboxField key={field.uid} {...commonProps} />
                );
            case 'Textarea':
                return (
                    <TextareaField key={field.uid} {...commonProps}/>
                );
            case 'Select': {
                return (
                    <SelectField key={field.uid} {...commonProps} options={field.Answers} />
                );
            }
            case 'Criteria': {
                return (
                    <MultiselectField key={field.uid} {...commonProps} options={field.Answers} />
                );
            }
        }
    });
    return (
        <form onSubmit={onSubmit}>
           {informMessage && <Message className={classes.message} intent={informMessage.type} message={informMessage.message} onClose={() => setInformMessage(null)} />}
            <div className={classes.fieldsBlock}>
                {fieldsHtml}
            </div>
            <Button
                appearance="primary"
                type="submit"
                disabled={submitting}
            >
                {submitting ? 'Submitting...' : 'Submit'}
            </Button>
        </form>
    );
}
