import React from "react";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {Field} from "@fluentui/react-components";

const onFormatDate = (date) => {
    return !date
      ? ""
      : (date.getMonth() + 1) +
          "/" +
          date.getDate() +
          "/" +
          date.getFullYear();
  };
const onParseDateFromString = (val) => {
    const previousValue = new Date();
    const newValueParts = (val || "").trim().split("/");
    const month =
      newValueParts.length > 0
        ? Math.max(1, Math.min(12, parseInt(newValueParts[0], 10))) - 1
        : previousValue.getMonth();
    const day =
        newValueParts.length > 1
          ? Math.max(1, Math.min(31, parseInt(newValueParts[1], 10)))
          : previousValue.getDate();
    let year =
      newValueParts.length > 2
        ? parseInt(newValueParts[2], 10)
        : previousValue.getFullYear();
    if (year < 100) {
      year +=
        previousValue.getFullYear() - (previousValue.getFullYear() % 100);
    }
    return new Date(year, month, day);
  };

export default function DateField({uid, label, value, onChange, required, validation}) {
    const handleOnChange = (date) => {
        onChange(uid, date);
    };

    return (
      <Field
      label={label}
      id={uid}
      required={required}
      validationState={validation[0]}
      validationMessage={validation[1]}
      validationMessageIcon=''
  >
      <DatePicker 
          name={uid} 
          value={value} 
          onSelectDate={handleOnChange}
          parseDateFromString={onParseDateFromString}
          allowTextInput
          formatDate={onFormatDate}
          autoComplete="off"
          placeholder='mm/dd/yyyy'
      />
  </Field>
    );
}