import React from "react";
import { makeResetStyles, makeStyles, Text, Field, Label, Input, Button, shorthands, mergeClasses } from "@fluentui/react-components";
import { Info24Filled } from "@fluentui/react-icons";
import { customTokens } from "../../utils/Theming";

const useBaseClassName = makeResetStyles({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: `calc(500px + ${customTokens.spacingHorizontalL})`,
    ...shorthands.margin('0', 'auto'),
    '& > .block': {
        '&:not(:last-child)': {
            marginBottom: customTokens.spacingVerticalL,
        },
    },
});
const useStyles = makeStyles({
    actionColor: {
        color: customTokens.colorButtonBackground1
    },
    actionBackground: {
        backgroundColor: customTokens.colorButtonBackground1
    },
    infoBlock: {
        '& > *': {
            verticalAlign: 'middle',
        },
        '& > svg': {
            marginRight: customTokens.spacingHorizontalS
        }
    },
});
export default function SecondFactorPart({onValidate}) {
    const baseClassName = useBaseClassName();
    const classes = useStyles();
    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        onValidate(formData.get('totp'));
    };

    return (
        
        <form onSubmit={onSubmit} className={baseClassName}>
            <Text className="block" weight="bold" size={600}>Insert your TOTP code</Text>
            <Field className="block">
                <Label htmlFor="totp" size="small" style={{marginBottom: '5px'}}>
                  Code
                </Label>
                <Input type="text" id="totp" name="totp" required autoComplete="one-time-code" />
              </Field>
            <div className={mergeClasses('block', classes.infoBlock)}>
                <Info24Filled className={classes.actionColor} />
                <Text size={200}>Open your authenticator app and fill in the code.</Text>
            </div>
            <Button className={mergeClasses('block', classes.actionBackground)} appearance="primary" type="submit">
                Validate
            </Button>
      </form>    
    );
}