import React from "react";
import { Button, Text, Field, Label, Input, Divider, makeResetStyles, makeStyles, mergeClasses, shorthands } from "@fluentui/react-components";
import { customTokens } from "../../utils/Theming";

const useBaseClassName = makeResetStyles({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: `calc(500px + ${customTokens.spacingHorizontalL})`,
    ...shorthands.margin('0', 'auto'),
    ...shorthands.padding('0', customTokens.spacingHorizontalL),
    '& > .block': {
        '&:not(:last-child)': {
            marginBottom: customTokens.spacingVerticalL,
        },
    },
});
const useStyles = makeStyles({
    actionColor: {
        color: customTokens.colorButtonBackground1
    },
    actionBackground: {
        backgroundColor: customTokens.colorButtonBackground1
    },
    infoBlock: {
        '& > *': {
            verticalAlign: 'middle',
        },
        '& > svg': {
            marginRight: customTokens.spacingHorizontalS
        }
    },
    divider: {
        "::before": {
      ...shorthands.borderColor(customTokens.colorDividerBackground1),
    },
    "::after": {
      ...shorthands.borderColor(customTokens.colorDividerBackground1),
    },
    }
});

export default function LoginPart({onLogin, onLoginWithAzure}) {
    const baseClassName = useBaseClassName();
    const classes = useStyles();

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        onLogin(formData.get('username'), formData.get('password'));
    };

    return (
        <form onSubmit={onSubmit} className={baseClassName}>
            <Text className="block" weight="bold" size={600}>Login with your account</Text>
            <Field className="block">
                <Label htmlFor="username" size="small" style={{marginBottom: '5px'}}>
                Username
                </Label>
                <Input type="text" id="username" required name="username" autoComplete="email" />
            </Field>
            <Field className="block">
                <Label htmlFor="password" size="small" style={{marginBottom: '5px'}}>
                Password
                </Label>
                <Input type="password" id="password" required name="password" />
            </Field>
            <Button className={mergeClasses('block', classes.actionBackground)} appearance="primary" type="submit">Login</Button>
            <Divider className={mergeClasses('block', classes.divider)}>Or</Divider>
            <Button className={mergeClasses('block')} onClick={onLoginWithAzure}>Login with Azure</Button>
        </form>
    )
}