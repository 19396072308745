import React from "react";
import { Field, Textarea } from "@fluentui/react-components";


export default function TextareaField({uid, label, value, onChange, required, validation}) {
    const handleOnChange = (e, {value}) => {
        onChange(uid, value);
    };

    return (
        <Field
                label={label}
                id={uid}
                required={required}
                validationState={validation[0]}
                validationMessage={validation[1]}
                validationMessageIcon=''
            >
                <Textarea 
                    name={uid} 
                    value={value} 
                    onChange={handleOnChange}
                    autoComplete="off"
                />
            </Field>
    );
}