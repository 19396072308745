import React, {useMemo} from "react";
import { Persona, makeResetStyles, shorthands } from "@fluentui/react-components";
import { customTokens } from "../../utils/Theming";


const useBaseClassName = makeResetStyles({
    ...shorthands.padding(customTokens.spacingVerticalL, customTokens.spacingHorizontalMNudge, customTokens.spacingVerticalMNudge),
});
// const useStyles = makeStyles({
// });

export default function CandidateRow({candidate}) {
    const baseClassName = useBaseClassName();
    // const classes = useStyles();

    const candidateName = useMemo(() => {
        if (!candidate) {
            return '';
        }
        return candidate.Person.firstName  + (candidate.Person.infix ? (' ' + candidate.Person.infix) : '') + ' ' + candidate.Person.lastName;
    }, [candidate]);

    let avatar = {
        color: 'colorful',
        idForColor: customTokens.colorBrandForeground2
    };
    if (candidate.profileImage) {
        avatar = {
            image: {
                src: `data:${candidate.profileImage.mime};base64,${candidate.profileImage.base64stream}`
            }
        };
    
    }

    return (
        <div className={baseClassName}>
            <Persona
                name={candidateName}
                secondaryText={candidate.status}
                presence={{ status: "available" }}
                size="huge"
                avatar={avatar}
            />
        </div>        
        );
}