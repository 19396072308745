import React, {useState, useRef, useLayoutEffect } from "react";
import FimHandler from "./FimHandler";
import {
    Tab,
    TabList,
    makeStyles,
    makeResetStyles,
    Overflow,
    OverflowItem,
    shorthands
} from "@fluentui/react-components"; 
import {
    Form24Regular,
    Form24Filled,
    bundleIcon,
} from "@fluentui/react-icons";  
import NoData from "../../blocks/NoData";
import { customTokens } from "../../utils/Theming";
import useSize from "@react-hook/size";
import OverflowMenu from "../../blocks/OverflowMenu";

const Form24 = bundleIcon(Form24Filled, Form24Regular);

function truncate(str) {
    return str.length > 32 ? str.substring(0, 29) + "..." : str;
}

const useBaseClassName = makeResetStyles({
    ...shorthands.padding(customTokens.spacingVerticalMNudge, customTokens.spacingHorizontalL, 0)
});
const useStyles = makeStyles({
    tabsContainer: {
        zIndex: 0,
        overflow: "hidden",
    },
    firstTabsRow: {
         backgroundColor: customTokens.colorTabsBackground1,
    }
 });

export default function MeetingFimForms({items, entity, ooid, onAddTabs}) {
    const baseClassName = useBaseClassName();
    const target = useRef(null);
    const [selectedForm, setSelectedForm] = useState(items[0]?.id || null);
    const classes = useStyles();
    const [width, ] = useSize(target);
    let tablistVertical = true;
    let itemsVisible = 4;
    if (width > 650) {
        tablistVertical = false;
        itemsVisible = 2
    }

    useLayoutEffect(() => {
        if(items?.length > 1) {
            const overflowItems = items.map((ff) => ({...ff, icon: <Form24 />, name: truncate(ff.name)}));
            onAddTabs(
                <div className={classes.tabsContainer}>
                    <Overflow minimumVisible={itemsVisible} overflowAxis={tablistVertical ? 'vertical': 'horizontal'}>
                        <TabList
                            className={classes.firstTabsRow} 
                            vertical={tablistVertical}
                            selectedValue={selectedForm}
                            onTabSelect={(_, {value}) => setSelectedForm(value)}
                        >
                            {items.map((ff) => {
                                return (
                                <OverflowItem
                                    key={ff.id}
                                    id={ff.id}
                                    priority={ff.id === selectedForm ? 2 : 1}
                                >
                                    <Tab key={ff.id} icon={<Form24 />} value={ff.id}>{truncate(ff.name)}</Tab>
                                </OverflowItem>
                                );
                            })}
                            <OverflowMenu onTabSelect={setSelectedForm} tabs={overflowItems} />
                        </TabList>
                    </Overflow>
                </div>
            );
        }
        return () => {
            onAddTabs(null);
        };
    }, [items, selectedForm, tablistVertical, itemsVisible, onAddTabs, classes.tabsContainer, classes.firstTabsRow]);

    let content = <FimHandler id={selectedForm} entity={entity} ooid={ooid} />;

    if (!items || items.length === 0) {
        content =  <NoData  />
    }
    return (
        <div className={baseClassName} ref={target}>
            {content}
        </div>
    );
}
