import React, { useRef } from "react";
import { Text, makeResetStyles, shorthands } from "@fluentui/react-components";
import { EmojiSad24Regular } from "@fluentui/react-icons";  
import useSize from "@react-hook/size";
import { customTokens } from "../utils/Theming";

const useBaseClassName = makeResetStyles({
    ...shorthands.padding(customTokens.spacingHorizontalL, customTokens.spacingHorizontalL),
    '& > *': {
        verticalAlign: 'middle',
    },
    '& > svg': {
        marginRight: customTokens.spacingHorizontalM,
    }
});

export default function NoData({message}) {
    const target = useRef(null);
    const baseClassName = useBaseClassName();
    const [width, ] = useSize(target); 
    let textSize = 300;
    if (width > 375) {
        textSize = 500;
    }
    
    if (!message) {
        message = "Sorry, there are no items to show....";
    }
    
    return (
        <div ref={target} className={baseClassName}>
            <EmojiSad24Regular />
            <Text size={textSize}>{message}</Text>
        </div>
    );
}