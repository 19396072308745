import * as React from "react";
import {
    makeStyles,
    Button,
    Menu,
    MenuList,
    MenuPopover,
    MenuTrigger,
    useOverflowMenu,
} from "@fluentui/react-components";
import { customTokens } from "../utils/Theming";
import OverflowMenuItem from "./OverflowMenuItem";
import {
    MoreHorizontalRegular,
    MoreHorizontalFilled,
    bundleIcon,
} from "@fluentui/react-icons";

const useOverflowMenuStyles = makeStyles({
    menu: {
      backgroundColor: customTokens.colorNeutralBackground1,
    },
    menuButton: {
      alignSelf: "center",
    },
});

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);
  
/**
 * A menu for selecting tabs that have overflowed and are not visible.
 */
export default function OverflowMenu({ onTabSelect, tabs}) {
    const { ref, isOverflowing, overflowCount } =
      useOverflowMenu();
  
    const styles = useOverflowMenuStyles();
  
    const onItemClick = (tabId) => {
      onTabSelect?.(tabId);
    };
  
    if (!isOverflowing) {
      return null;
    }
  
    return (
      <Menu hasIcons>
        <MenuTrigger disableButtonEnhancement>
          <Button
            appearance="transparent"
            className={styles.menuButton}
            ref={ref}
            icon={<MoreHorizontal />}
            aria-label={`${overflowCount} more tabs`}
            role="tab"
          />
        </MenuTrigger>
        <MenuPopover>
          <MenuList className={styles.menu}>
            {tabs.map((tab) => (
              <OverflowMenuItem
                key={tab.id}
                tab={tab}
                onClick={() => onItemClick(tab.id)}
              />
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    );
};