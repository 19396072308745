import React, { useMemo } from "react";
import NoData from "../../blocks/NoData";
import { Divider, Title3, Subtitle2, Body1, makeStyles } from "@fluentui/react-components";
import { customTokens } from "../../utils/Theming";
import DOMPurify from "dompurify";

const useStyles = makeStyles({
    infoListContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: customTokens.spacingVerticalMNudge,
    },
    infoListTitle: {
        marginBottom: customTokens.spacingVerticalMNudge,
        fontWeight: 'bold'
    }
});

export default function CandidateEducationHistory({data}) {
  const classes = useStyles();
  function renderDate(startDate, endDate) {
      if (startDate && endDate) {
          return `${startDate} - ${endDate}`;
      } else if (startDate) {
          return `From ${startDate}`;
      } else if (endDate) {
          return `Until ${endDate}`;
      }
      return '';
  }
  const eduList = useMemo(() => {
    if (typeof data === 'undefined') {
      return [];
    }
    return Object.values(data).sort((a, b) => a.rank - b.rank);
  }, [data]);
  const eduSubjects = (subjects) => ({__html: DOMPurify.sanitize(subjects)});

  return (
      <div>
          <Title3 block={true} className={classes.infoListTitle}>Education history</Title3>                
          {eduList.length > 0 ? eduList.map(education => (
              <div className={classes.infoListContainer} key={education.uid}>
                {education.degree && <Title3 truncate wrap={false} block={true}>{education.degree}</Title3>}
                { (education.startDateFormatted || education.endDateFormatted) &&
                  <Subtitle2>{renderDate(education.startDateFormatted, education.endDateFormatted)}</Subtitle2>
                }
                { education.subjects && 
                  <Body1>
                    <div dangerouslySetInnerHTML={eduSubjects(education.subjects)} />
                  </Body1>}
                { education.institute && <Body1>{education.institute}</Body1>}
                { education.diplomaDate && <Body1>{education.diplomaDate}</Body1>}
                <Divider />
              </div>
          )) : (<NoData/>)}
      </div>
  );
}