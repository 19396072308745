import React from "react";
import CandidatePage from "../features/candidate/CandidatePage";
import ProcedurePage from "../features/procedure/ProcedurePage";
import NoData from "../blocks/NoData";
import { MeetingContext } from "../utils/Constants";
import { useLoaderData } from "react-router-dom";


export default function MeetingDetailsPage() {
    // const [meeting, setMeeting] = useState(null);
    const meeting = useLoaderData();

    if (! meeting || !meeting.appointment) {
        return (
            <NoData message="Sorry, unable to find appointment" />
        )
    }
    if (!meeting.context) {
        return (
            <NoData message="Sorry, there is no context for this meeting" />
        )
    }
    return <>
        {meeting.context.context === MeetingContext.CANDIDATE && 
            <CandidatePage ooid={meeting.context.context_uid} fim_forms={meeting.fim_forms} />
        }
        {meeting.context.context === MeetingContext.PROCEDURE &&
            <ProcedurePage ooid={meeting.context.context_uid} fim_forms={meeting.fim_forms} />
        }
    </>;
}
//a6cc8350bf70140846270c11b3cbb3bc
//a6cc8350bf70140846270c11b3cbb3bc