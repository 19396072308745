import AppStorage from "./AppStorage";
import Api from "./ApiHandler";

const requestHeaders = {
    'Content-type': 'application/json; charset=UTF-8',
};

const OWS_HOST = process.env.REACT_APP_OWS_DOMAIN;
const MAX_RETRIES = 5;
const APP_NAME = 'teams-addon';
const OWS_STATE = 'ows';

class Ows {
    constructor() {
        this.retryConut = 0;
        this.counter = 1;
    }
    getState() {
        return AppStorage.getState(OWS_STATE);
    }
    setState(state) {
        return AppStorage.setState(OWS_STATE, state);
    }

    async _getOwsToken() {
        const state = this.getState();
        if (state && state.token) {
            return state.token;
        }

        try {
            const key = await this._getOwsKey();
            const reponse = await fetch(`https://${OWS_HOST}/jservice.php`, {
                method: 'POST',
                body: JSON.stringify({
                    jsonrpc: "2.0",
                    method: "loginByUid",
                    params: [
                        key
                    ],
                    id: 1
                }),
                headers: requestHeaders
            });
            const data = await reponse.json();
            if (data.result) {
                this.setState({ token: data.result });
                return data.result;
            } else {
                throw new Error('Failed to login via SSO');
            }

        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    async _getOwsKey() {
        return await Api.getKey();
    }
    _processResponse(data) {
        if (typeof data.result !== 'undefined') {
            return { response: data.result, error: false };
        } else {
            if (data.error.message.indexOf('denied') !== -1 && data.error.message.indexOf('login') !== -1) {
                if (this.retryConut <= MAX_RETRIES) {
                    this.setState({ token: null });
                    throw new Error('retry');
                } else {
                    return { response: false, error: 'Too many retries' };
                }
            }
            return { response: false, error: data.error };
        }
    }

    async getCurrentUser() {
        try {
            const token = await this._getOwsToken();
            const response = await fetch(`https://${OWS_HOST}/jservice.php`, {
                method: 'POST',
                body: JSON.stringify({
                    jsonrpc: "2.0",
                    method: "check",
                    params: [
                        token
                    ],
                    id: 1
                }),
                headers: requestHeaders
            });
            const data = await response.json();
            if (data.result) {
                return data.result;
            } else {
                throw new Error(data.error.message);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async checkTotpBlock() {
        try {
            const token = await this._getOwsToken();
            const response = await fetch(`https://${OWS_HOST}/jservice.php`, {
                method: 'POST',
                body: JSON.stringify({
                    jsonrpc: "2.0",
                    method: "Otys.Services.UserService.retrieveSession",
                    params: [
                        token
                    ],
                    id: 1
                }),
                headers: requestHeaders
            });
            const data = await response.json();
            if (data.result) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.error(error);
            return true;
        }
    }

    async handleRequest(service, params) {
        try {
            const token = await this._getOwsToken();
            const reponse = await fetch(`https://${OWS_HOST}/jservice.php`, {
                method: 'POST',
                body: JSON.stringify({
                    jsonrpc: "2.0",
                    method: service,
                    params: [
                        token,
                        ...params
                    ],
                    id: ++this.counter
                }),
                headers: requestHeaders
            });

            const data = await reponse.json();
            return this._processResponse(data);
        } catch (error) {
            if (error.message === 'retry') {
                this.retryConut++;
                return this.handleRequest(service, params);
            }
            console.error(error);
            throw error;
        }
    }

    async handleMutliRequest(params) {
        try {
            const token = await this._getOwsToken();
            const response = await fetch('https://ows.otys.nl/jservice.php', {
                method: 'POST',
                body: JSON.stringify({
                    jsonrpc: "2.0",
                    method: 'Otys.Services.MultiService.execute',
                    params: [
                        params.map((param) => ({
                            method: param.method,
                            args: [
                                token,
                                ...param.args
                            ]
                        }))
                    ],
                    id: ++this.counter
                }),
                headers: requestHeaders
            });
            const data = await response.json();
            return this._processResponse(data);
        } catch (error) {
            if (error.message === 'retry') {
                this.retryConut++;
                return this.handleMutliRequest(params);
            }
            console.error('error', error);
            throw error;
        }
    }
    async validateViaTotp(totpCode) {
        const user = await this.getCurrentUser();
        try {
            const response = await fetch(`https://${OWS_HOST}/jservice.php`, {
                method: 'POST',
                body: JSON.stringify({
                    jsonrpc: "2.0",
                    method: "checkTotpcode",
                    params: [
                        user,
                        totpCode
                    ],
                    id: ++this.counter
                }),
                headers: requestHeaders
            });
            const data = await response.json();
            if (data.result && data.result.verified) {
                return;
            } else {
                throw new Error(data.error.message || 'Invalid TOTP code');
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async validateViaMsUser(key) {
        this.setState({});
        try {
            const response = await fetch(`https://${OWS_HOST}/jservice.php`, {
                method: 'POST',
                body: JSON.stringify({
                    jsonrpc: "2.0",
                    method: "loginByMsUser",
                    params: [
                        key
                    ],
                    id: ++this.counter
                }),
                headers: requestHeaders
            });
            const data = await response.json();
            if (data.result) {
                this.setState({ token: data.result });
                return data.result;
            } else {
                throw new Error(data.error.message);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async validateViaUsernamePassword(username, password) {
        this.setState({});

        let isTOTPActive = false;

        try {
            const responses = await Promise.all([
                fetch(`https://${OWS_HOST}/jservice.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        jsonrpc: "2.0",
                        method: "getTotpIsActive",
                        params: [
                            username,
                            password,
                        ],
                        id: ++this.counter
                    }),
                    headers: requestHeaders
                }),
                fetch(`https://${OWS_HOST}/jservice.php`, {
                    method: 'POST',
                    body: JSON.stringify({
                        jsonrpc: "2.0",
                        method: "login",
                        params: [
                            username,
                            password,
                            null,
                            null,
                            APP_NAME
                        ],
                        id: ++this.counter
                    }),
                    headers: requestHeaders
                })
            ]).then(responses => Promise.all(responses.map(response => response.json())));

            isTOTPActive = responses[0].result;
            const data = responses[1];
            if (data.result) {
                this.setState({ token: data.result });
                return isTOTPActive;
            } else {
                throw new Error(data.error.message);
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async downloadImage(ouid, type = 'CandidatePhoto') {
        const token = await this._getOwsToken();
        try {
            const reponse = await fetch(`https://ows.otys.nl/fileService.php?ouid=${ouid}&class=${type}&mode=1&fingerprint=${Date.now()}&sessionId=${token}`);
            const blob = await reponse.blob();
            if (blob.size === 0) {
                return null;
            } else {
                return URL.createObjectURL(blob);
            }
        } catch (error) {
            console.error('error', error);
            return null
        }

    }
    async logout() {
        this.setState({});
    }
    async configureSSO() {
        try {
            const key = await this._getOwsKey();
            await this.handleRequest('Otys.Services.ExternalApiService.updateMsAuthToken', [key]);
        } catch (error) {
            console.error('error', error);
        }
    }
}

const inst = new Ows();

export default inst;