export function validateMandatory(value) {
    return (
        (typeof value === 'string' && value.trim() !== '') ||
        (Array.isArray(value) && value.length > 0)
    );
}

export function validateEmail(value) {
    if (isEmpty(value)) {
        return true;
    }
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

export function validateMaxLength(value, length) {
    if (isEmpty(value)) {
        return true;
    }
    return value.length <= length;
}

export function validateLettersOnly(value) {
    if (isEmpty(value)) {
        return true;
    }
    return /^\p{L}+$/u.test(value);
}

export function validateRegex(value, regex) {
    if (isEmpty(value)) {
        return true;
    }
    return new RegExp(regex).test(value);
}

export function formatLettersCapitalize(value) {
    if (isEmpty(value)) {
        return '';
    }
    return value.replace(/\b\w/g, (char) => char.toUpperCase());
}

export function formatFirstLetterCapitalize(value) {
    if (isEmpty(value)) {
        return '';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function formatAllWordsFirstLetterCapitalize(value) {
    if (isEmpty(value)) {
        return '';
    }

    return value
        .split(' ')
        .map(word => {
            if (word.trim().length === 0) return '';
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ')
        .replace(/\s+/g, ' ');
}

function isEmpty(value) {
    return (typeof value === 'string' && value.trim() === '') || value === null || value === undefined;
}