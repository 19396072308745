import { AES, enc } from 'crypto-js';

/**
 * Class representing the application storage.
 */
class AppStorage {
    /**
     * Create an instance of AppStorage.
     * @param {Storage} storage - The storage object to use (default is sessionStorage).
     */
    constructor(storage = window.sessionStorage) {
        this.state = {};
        this.storageKey = 'OtysApp';
        this.storageKeyNew = 'a23s5d4ad54a2s5d4asda';
        this.storage = storage;
    }

    /**
     * Generate the block string.
     * @returns {string} The generated block string.
     */
    _getBlock() {
        return `${this.storageKey}${this.storageKeyNew}${this.storageKeyNew}`;
    }

    /**
     * Generate the FBlock string.
     * @returns {string} The generated FBlock string.
     */
    _getFBlock() {
        return this._getBlock().split("").reverse().join(this.storageKey);
    }

    /**
     * Get the global state from storage.
     * @returns {object} The global state.
     * @throws Will throw an error if decryption fails.
     */
    getGlobalState() {
        let storage = this.storage.getItem(this.storageKey);

        if (!storage) {
            storage = AES.encrypt(JSON.stringify(this.state), this._getFBlock()).toString();
            this.storage.setItem(this.storageKey, storage);
        }

        try {
            const decryptedData = AES.decrypt(storage, this._getFBlock()).toString(enc.Utf8);
            return JSON.parse(decryptedData);
        } catch (error) {
            console.error('Failed to decrypt global state:', error);
            return this.state;
        }
    }

    /**
     * Set the global state in storage.
     * @param {object} state - The state to set.
     * @returns {object} The updated state.
     */
    setGlobalState(state) {
        const encryptedState = AES.encrypt(JSON.stringify(state), this._getFBlock()).toString();
        this.storage.setItem(this.storageKey, encryptedState);
        return state;
    }

    /**
     * Get the state of a specific segment.
     * @param {string} segment - The segment to get the state for.
     * @returns {object} The state of the segment.
     */
    getState(segment) {
        const globalState = this.getGlobalState();

        if (!globalState[segment]) {
            globalState[segment] = {};
            this.setGlobalState(globalState);
        }

        return globalState[segment];
    }

    /**
     * Set the state of a specific segment.
     * @param {string} segment - The segment to set the state for.
     * @param {object} state - The state to set for the segment.
     * @returns {object} The updated state of the segment.
     */
    setState(segment, state) {
        const globalState = this.getGlobalState();
        const currentState = this.getState(segment);
        if (Object.keys(state).length === 0) {
            delete globalState[segment];
            this.setGlobalState(globalState);
            return {};
        }
        const newState = {...currentState, ...state };
        const newGlobalState = {...globalState, [segment]: newState };

        this.setGlobalState(newGlobalState);
        return newState;
    }
}
const inst = new AppStorage();

export default inst;