import { Subtitle2, Title3, Body1, makeStyles, shorthands, mergeClasses } from "@fluentui/react-components";
import React, {useRef}  from "react";
import { customTokens } from "../../utils/Theming";
import useSize from "@react-hook/size";


const useStyles = makeStyles({
    root: {
        ...shorthands.margin(customTokens.spacingVerticalMNudge, 'auto'),
    },
    typeAndTime: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    typeAndTimeSmall: {
        flexDirection: 'column',
        gap: customTokens.spacingVerticalXS,
    },
    subject: {
        display: 'block',
        ...shorthands.margin(customTokens.spacingVerticalXS, 0),
    },
    noteText: {
        color: customTokens.colorNeutralForeground2,
    },
    author: {
        display: 'block',
        marginBottom: customTokens.spacingVerticalMNudge,
    }
})

export default function NoteItem({note}) {
    const classes = useStyles();
    const target = useRef(null);
    const [width, ] = useSize(target);
    let time = ''
    if (note.dt) {
        // parse date like "20240703T10:07:12"
        let year = note.dt.substring(0, 4);
        let month = note.dt.substring(4, 6);
        let day = note.dt.substring(6, 8);
        let date = new Date(`${year}-${month}-${day}${note.dt.substring(8)}`);
        time = date.toLocaleString();
    }

    return (
        <div ref={target} className={classes.root}>
            <div className={mergeClasses(classes.typeAndTime, width > 450 ? '': classes.typeAndTimeSmall)}>
                <Subtitle2>{note.pvar5}</Subtitle2>
                {time !== '' && <Subtitle2>{time}</Subtitle2>}
            </div>
            <Title3 truncate wrap={false} className={classes.subject}>{note.subject}</Title3>
            <Subtitle2 className={classes.author}>{note.from}</Subtitle2>
            <Body1 className={classes.noteText}>{note.text}</Body1>
        </div>
    );
}