import React from "react";
import { Field, Dropdown, Option } from "@fluentui/react-components";

export default function SelectField({uid, label, value, onChange, required, validation, options}) {
    const handleOnChange = (e, {selectedOptions}) => {
        onChange(uid, selectedOptions[0]);
    };
    const valueStr = options[value]?.answer || '';

    return (
        <Field
                label={label}
                id={uid}
                required={required}
                validationState={validation[0]}
                validationMessage={validation[1]}
                validationMessageIcon=''
            >
                <Dropdown
                    name={uid} 
                    value={valueStr}
                    selectedOptions={[value || '']}
                    options={options}
                    onOptionSelect={handleOnChange}
                    placeholder="Select value"
                >
                    <Option value="">Not selected</Option>
                    {
                        Object.values(options).sort((a,b) => a.rank - b.rank).map((answer) => 
                            (<Option value={answer.uid}>{answer.answer}</Option>)
                        )
                    }
                </Dropdown>
            </Field>
    );
}