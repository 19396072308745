import * as React from "react";

import {
    MenuItem,
    useIsOverflowItemVisible,
} from "@fluentui/react-components";
/**
 * A menu item for an overflow menu that only displays when the tab is not visible
 */
export default function OverflowMenuItem({ tab, onClick }) {
    const isVisible = useIsOverflowItemVisible(tab.id);

    if (isVisible) {
        return null;
    }

    return (
        <MenuItem key={tab.id} icon={tab.icon} onClick={onClick}>
            <div>{tab.name}</div>
        </MenuItem>
    );
}