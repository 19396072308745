import React, { useRef, useEffect, useState, useCallback} from "react";
import {
    Tab,
    TabList,
    Spinner,
    makeStyles,
} from "@fluentui/react-components";
import {
    DocumentEndnote24Filled,
    DocumentEndnote24Regular,
    Person24Filled,
    Person24Regular,
    Note24Filled,
    Note24Regular,
    bundleIcon,
} from "@fluentui/react-icons";  
import { customTokens } from "../../utils/Theming";
import useSize from "@react-hook/size";
import Notes from "../note/Notes";
import CandidateInfo from "./CandidateInfo";
import CandidateRow from "./CandidateRow";
import MeetingFimForms from "../fim/MeetingFimForms";
import { useDispatch, useSelector } from "react-redux";
import { fetchCandidate, setCandidateOoid } from "./candidate-slice";

const DocumentEndnote24 = bundleIcon(DocumentEndnote24Filled, DocumentEndnote24Regular);
const Person24 = bundleIcon(Person24Filled, Person24Regular);
const Note24 = bundleIcon(Note24Filled, Note24Regular);

// const useBaseClassName = makeResetStyles({
// });
const useStyles = makeStyles({
   staticRow: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: customTokens.colorNeutralBackground7
   }
});

export default function CandidatePage({ooid, fim_forms}) {
    const {candidate, loading, error} = useSelector(state => state.candidate);
    const dispatch = useDispatch();
    // const baseClassName = useBaseClassName();
    const classes = useStyles();
    const target = useRef(null);
    const [tab, setTab] = useState('tab2');
    const [additionalTabList, setAdditionalTabList] = useState(null);
    const [width, ] = useSize(target);

    let tablistSize = 'small';
    if (width > 275){
        tablistSize = 'medium'
    }
    let tabInfoText = 'Info';
    let tabNotesText = 'C - Notes';
    if (width > 375) {
        tabInfoText = 'Information';
        tabNotesText = 'Candidate Notes';
    
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [tab]);

    useEffect(() => {
        dispatch(setCandidateOoid(ooid));
        dispatch(fetchCandidate());
    }, [ooid]);

    const handleMoreTabs = useCallback((tabs) => {setAdditionalTabList(tabs);}, [setAdditionalTabList]);

    const renderTab = () => {
        switch(tab) {
            case 'tab1':
                return <MeetingFimForms items={fim_forms} entity="Candidate" ooid={ooid} onAddTabs={handleMoreTabs} />;
            case 'tab2':
                return <CandidateInfo ooid={ooid} candidate={candidate} onAddTabs={handleMoreTabs} />;
            case 'tab3':
                return <Notes ooid={ooid} type='candidate'/>;
            default:
                return null;
        }
    };

    let componentContent = <Spinner label="I am definitely loading..." />;
    if (candidate) {
        componentContent = (
            <>
                <div className={classes.staticRow}>
                    <CandidateRow candidate={candidate} />
                    <TabList selectedValue={tab} onTabSelect={(e, {value}) => setTab(value)} size={tablistSize}>
                        {fim_forms && (<Tab icon={<DocumentEndnote24 />} value="tab1">{fim_forms.length >= 2 ? ('IForms') : ('IForm')}</Tab>)}
                        <Tab icon={<Person24 />} value="tab2">{tabInfoText}</Tab>
                        <Tab icon={<Note24 />} value="tab3">{tabNotesText}</Tab>                
                    </TabList>
                    {additionalTabList}
                </div>
                {renderTab()}
            </>
        );
    }


    return (
        <div ref={target}>
            {componentContent}
        </div>
    );
}