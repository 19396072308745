import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Ows from "../../utils/OwsHandler";


export const procedureSlice = createSlice({
    name: "procedure",
    initialState: {
        ooid: null,
        procedure: null,
        loading: false,
        error: null,
    },
    reducers: {
        setProcedureOoid: (state, action) => {
            state.ooid = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProcedure.fulfilled, (state, action) => {
            state.loading = false;
            state.procedure = action.payload;
        });
        builder.addCase(fetchProcedure.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchProcedure.pending, (state) => {
            state.loading = true;
        });
    },
});

export const fetchProcedure = createAsyncThunk('procedure/fetch', async(_, { getState, rejectWithValue }) => {
    const { procedure: { ooid } } = getState();
    if (!ooid) {
        return rejectWithValue('No ooid found');
    }
    try {
        const { response } = await Ows.handleRequest(
            'Otys.Services.ProcedureService.getDetail', [
                ooid,
                {
                    candidateUid: true,
                    vacancyUid: true,
                    Candidate: {
                        Person: true,
                        profileImage: true,
                    },
                    Vacancy: {
                        title: true,
                        relationId: true,
                        relationContactId: true,
                        vacancyImage: true,
                    },
                    ProcedureStatus1: {
                        status: true,
                    }
                }
            ]
        );
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const { setProcedureOoid } = procedureSlice.actions;