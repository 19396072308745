import React from "react";
import { Button, Text, makeStyles, makeResetStyles, shorthands, mergeClasses } from "@fluentui/react-components";
import { Info24Filled } from "@fluentui/react-icons";
import Logo from "../../blocks/Logo";
import { customTokens } from "../../utils/Theming";

const useBaseClassName = makeResetStyles({
    display: 'flex',
    flexDirection: 'column',
    '& > .block': {
        textAlign: 'center',
        '&:not(:last-child)': {
            marginBottom: customTokens.spacingVerticalXL,
        },
        '& > *': {
            verticalAlign: 'middle',
        }
    },
});
const useStyles = makeStyles({
    firstRow: {
        '& > *': {
            ...shorthands.margin(0, customTokens.spacingHorizontalL)            
        }
    },
    secondRow: {
        '& > *': {
            ...shorthands.margin(0, customTokens.spacingHorizontalXXS)            
        }
    },
    thirdRow: {
        ...shorthands.padding(customTokens.spacingVerticalS, 0)
    },
    actionColor: {
        color: customTokens.colorButtonBackground1
    },
    actionBackground: {
        backgroundColor: customTokens.colorButtonBackground1
    }
});

export default function SignedPart({name, onLogout}) {
    const baseClassName = useBaseClassName();
    const classes = useStyles();

    return (
        <div className={baseClassName}>
            <div className={mergeClasses('block', classes.firstRow)}>
                <Logo width="70"/>
                <Text weight="bold" size={600}>Hey “{name}”</Text>
            </div>
            
            <div className={mergeClasses('block', classes.secondRow)}>
                <Info24Filled className={classes.actionColor}/>
                <Text size={400}>Hit save to continue to our Teams Add in</Text>
            </div>
            
            <div className={mergeClasses('block', classes.thirdRow)}>
                <Button className={classes.actionBackground} appearance="primary" onClick={onLogout}>Logout</Button>
            </div>
        </div>
    );
}