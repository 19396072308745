import { Button, makeResetStyles, shorthands } from "@fluentui/react-components";
import React from "react";
import { customTokens } from "../utils/Theming";
import NoData from "../blocks/NoData";
import { useNavigate } from "react-router-dom";

const useBaseClassName = makeResetStyles({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...shorthands.padding(customTokens.spacingHorizontalL, customTokens.spacingHorizontalL),
    '& > button': {
        display: 'block',
        marginTop: customTokens.spacingVerticalL
    }
});

export default function BadConfigPage() {
    const navigate = useNavigate();
    const baseClassName = useBaseClassName();
    const refresh = () => {
        navigate('/meeting-detail');
    };
    return (
        <div className={baseClassName}>
            <NoData message="Unfortunately your authentication has expired, please open settings of this tab and login once again." />
            <Button onClick={refresh}>Refresh</Button>
        </div>
    );
}