import React, {useRef} from "react";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    Spinner,
    makeStyles,
  } from "@fluentui/react-components";
import {
    Person24Regular,
    Location24Regular,
    Call24Regular,
    Mail24Regular,
    AccessibilityCheckmark24Regular,
    Flag24Regular,
  } from "@fluentui/react-icons";  
import { customTokens } from "../../utils/Theming";
import { safeAddress } from "../../utils/Helpers";
import useSize from "@react-hook/size";

function getGenderFullName(short_code) {
    let name = 'Other';

    if(short_code === 'M') {
        name = 'Male';
    }

    if(short_code === 'F') {
        name = 'Female';
    }

    return name;
}

const useStyles = makeStyles({
    label: {
        display: 'flex',
        alignItems: 'center',
    },
    labelContent: {
        display: 'inline-block',
        paddingLeft: '15px',
        position: 'relative',
        top: '-2px',
        color: customTokens.colorNeutralForeground2
    },
    fontSmall: {
        fontSize: customTokens.fontSizeBase300,
    }
});


export default function CandidateTable({data: candidate, context = 'canidate'}) {
    const classes = useStyles();
    const target = useRef(null);
    const [width, ] = useSize(target);

    let tableSize = 'small';
    if (width > 300){
        tableSize = 'medium'
    }


    const tableContent = [];
    if (candidate) {
        if (context === 'procedure') {
            tableContent.push({
                key: 'name',
                icon: <Person24Regular />,
                label: candidate.Person.firstName  + (candidate.Person.infix ? (' ' + candidate.Person.infix) : '') + ' ' + candidate.Person.lastName
            })
        }
        if (candidate.Person.AddressPrimary) {
            const location = safeAddress(', ', candidate.Person.AddressPrimary.address, [candidate.Person.AddressPrimary.city, candidate.Person.AddressPrimary.postcode], candidate.Person.AddressPrimary.country);
            if (location) {
                tableContent.push({
                    key: 'location',
                    icon: <Location24Regular />,
                    label: location
                });
            }
        
        }
        if (candidate.Person.phoneMobile) {
            tableContent.push({
                key: 'phoneMobile',
                icon: <Call24Regular />,
                label: candidate.Person.phoneMobile
            });
        }
        if (candidate.Person.emailPrimary) {
            tableContent.push({
                key: 'emailPrimary',
                icon: <Mail24Regular />,
                label: candidate.Person.emailPrimary
            });
        }
        if (candidate.Person.gender) {
            tableContent.push({
                key: 'gender',
                icon: <AccessibilityCheckmark24Regular />,
                label: getGenderFullName(candidate.Person.gender)
            });
        }
        tableContent.push({
            key: 'nationality',
            icon: <Flag24Regular />,
            label: candidate.Person.nationality ? candidate.Person.nationality : 'Unknown'
        });
    }

    let componentContent = <Spinner label="I am definitely loading..." />;

    if(candidate) {
        componentContent = (
            <Table size={tableSize} className={width > 275 ? '': classes.fontSmall}>
                <TableBody>
                    {tableContent.map((item) => (
                        <TableRow key={item.key}>
                            <TableCell className={classes.label}>
                                {item.icon}
                                <div className={classes.labelContent}>
                                    {item.label}
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );    
    }

    return (
        <div ref={target}>
            {componentContent}
        </div>
    );
}