import React, {useMemo} from "react";
import { Persona, AvatarGroup, AvatarGroupItem, Label, makeStyles, shorthands, makeResetStyles } from "@fluentui/react-components";
import { customTokens } from "../../utils/Theming";

const useBaseClassName = makeResetStyles({
    ...shorthands.padding(customTokens.spacingVerticalL, customTokens.spacingHorizontalMNudge, customTokens.spacingVerticalMNudge),
});
const useStyles = makeStyles({
    personaLabel: {
        ...shorthands.padding(customTokens.spacingVerticalSNudge, customTokens.spacingHorizontalMNudge),
        borderRadius: '15px',
        display: 'inline-block',
        backgroundColor: customTokens.colorBadgeBackground,
    },
    personaPrimaryText: {
        marginBottom:'5px'
    },
    avatarGroup: {
        gridRowStart: 'span 5',
        alignSelf: 'start',
        marginRight: customTokens.spacingHorizontalM,
    }
});

export default function ProcedureRow({procedure}) {
    const baseClassName = useBaseClassName();
    const classes = useStyles();
    const candidate = procedure ? procedure.Candidate : null;
    const vacancy = procedure ? procedure.Vacancy : null;

    const candidateName = useMemo(() => {
        if (!candidate) {
            return '';
        }
        return candidate.Person.firstName  + (candidate.Person.infix ? (' ' + candidate.Person.infix) : '') + ' ' + candidate.Person.lastName;
    }, [candidate]);

    const renderAvatarGroup = (Component, props) => {
        let vacancyImage = null;
        let candidateImage = null;
        if (vacancy?.vacancyImage) {
            vacancyImage = {
                src: `data:${vacancy.vacancyImage.mime};base64,${vacancy.vacancyImage.base64stream}`
            };
        }
        if (candidate?.profileImage) {
            candidateImage = {
                src: `data:${candidate.profileImage.mime};base64,${candidate.profileImage.base64stream}`
            };
        }
    return (
        <AvatarGroup className={classes.avatarGroup} layout="stack" size={56}>
            <AvatarGroupItem name={vacancy?.title} key='vacancy' image={vacancyImage} />
            <AvatarGroupItem name={candidateName} key='candidate' image={candidateImage} />
        </AvatarGroup>
        )
    };

    return (
        <div className={baseClassName}>
            <Persona
                primaryText={
                    <>
                        {procedure.ProcedureStatus1.status && <Label size="small" className={classes.personaLabel}>{procedure.ProcedureStatus1.status}</Label>}
                        <div className={classes.personaPrimaryText}>{candidateName}</div>
                    </>
                }
                secondaryText={vacancy.title}
                size="huge"
                avatar={{ children: renderAvatarGroup}}
            />
        </div>        
        );
}