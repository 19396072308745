import React, {useEffect, useState, useRef} from "react";
import Ows from "../../utils/OwsHandler";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    makeStyles,
    Spinner,
  } from "@fluentui/react-components";
import {
    BuildingTownhouse24Regular,
    PersonMail24Regular,
    Location24Regular,
    PersonChat24Regular,
    Globe24Regular,
    Call24Regular,
  } from "@fluentui/react-icons";  
import useSize from "@react-hook/size";
import NoData from "../../blocks/NoData";
import { customTokens } from "../../utils/Theming";
import { safeAddress } from "../../utils/Helpers";


const useStyles = makeStyles({
    label: {
        display: 'flex',
        alignItems: 'center',
    },
    labelContent: {
        display: 'inline-block',
        paddingLeft: '15px',
        position: 'relative',
        top: '-2px',
        color: customTokens.colorNeutralForeground2
    },
    fontSmall: {
        fontSize: customTokens.fontSizeBase300,
    }
});

export default function ProcedureRelationTable({ooid}) {
    const classes = useStyles();
    const [relation, setRelation] = useState(null);
    const target = useRef(null);
    const [width, ] = useSize(target);

    let tableSize = 'small';
    if (width > 300){
        tableSize = 'medium'
    }
    useEffect(() => {
        (async () => {
            const {response} = await Ows.handleRequest(
                'Otys.Services.RelationService.getDetail',
                [
                    ooid,
                    {
                        relation: true,
                        email: true,
                        Addresses: true,
                        phoneNumberMain: true,
                        user: true,
                        website: true,
                    }
                ]
            );
            setRelation(response);    
        })();
    }, [ooid]);

    const tableContent = [];
    if (relation){
        if (relation.relation) {
            tableContent.push({
                key: 'relation',
                icon: <BuildingTownhouse24Regular />,
                label: relation.relation,
            });
        }
        if (relation.email) {
            tableContent.push({
                key: 'email',
                icon: <PersonMail24Regular />,
                label: relation.email,
            });
        }
        if (relation.Addresses.main) {
            const address = safeAddress(', ', relation.Addresses.main.address, [relation.Addresses.main.city, relation.Addresses.main.postcode], relation.Addresses.main.country);
            if (address !== ''){
                tableContent.push({
                    key: 'address',
                    icon: <Location24Regular />,
                    label: address,
                });
            }
        }
        if (relation.phoneNumberMain) {
            tableContent.push({
                key: 'phoneNumberMain',
                icon: <Call24Regular />,
                label: relation.phoneNumberMain,
            });
        }
        if (relation.user) {
            tableContent.push({
                key: 'user',
                icon: <PersonChat24Regular />,
                label: relation.user,
            });
        }
        if (relation.website) {
            tableContent.push({
                key: 'website',
                icon: <Globe24Regular />,
                label: relation.website,
            });
        }
    }
    let componentContent = <NoData message="Sorry, there is no relation for this procedure" />;
    
    if (relation === null) {
        componentContent = <Spinner label="I am definitely loading..." />;
    }

    if(relation) {
        componentContent = (
            <Table size={tableSize} className={width > 275 ? '': classes.fontSmall}>
                <TableBody>
                    {tableContent.map((item) => (
                        <TableRow key={item.key}>
                            <TableCell className={classes.label}>
                                {item.icon}
                                <div className={classes.labelContent}>
                                    {item.label}
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );    
    }

    return (
        <div ref={target}>
            {componentContent}
        </div>
    );
}