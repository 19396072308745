import React from "react";
import { Field, Checkbox } from "@fluentui/react-components";


export default function CheckboxField({uid, label, value, onChange, required, validation}) {
    const handleOnChange = (e, {checked}) => {
        onChange(uid, checked);
    };

    return (
        <Field
                label={label}
                id={uid}
                required={required}
                validationState={validation[0]}
                validationMessage={validation[1]}
                validationMessageIcon=''
            >
                <Checkbox 
                    name={uid} 
                    checked={value} 
                    onChange={handleOnChange}
                />
            </Field>
    );
}