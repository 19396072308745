import { authentication } from "@microsoft/teams-js";

const apiEndpoint = process.env.REACT_APP_FUNC_ENDPOINT;

const url = `${apiEndpoint}/api`;

class Api {


    async getKey() {
        const token = await authentication.getAuthToken({ silent: true });
        try {
            const response = await fetch(`${url}/getUserKey`, {
                method: "POST",
                body: JSON.stringify({
                    token
                })
            }).then((res) => res.json());
            return response.key;
        } catch (e) {
            console.error(e);
            throw new Error("Failed to get user key");
        }
    }
}
const obj = new Api();

export default obj;