import React from "react";
import { Field, Dropdown, Option } from "@fluentui/react-components";


export default function MultiselectField({uid, label, value, onChange, required, validation, options}) {
    const handleOnChange = (e, {selectedOptions}) => {
        onChange(uid, selectedOptions);
    };

    const valueStr = options
        .filter(option => value.includes(option.uid))
        .map(option => option.value).join(', ') || '';
    

    return (
        <Field
            label={label}
            id={uid}
            required={required}
            validationState={validation[0]}
            validationMessage={validation[1]}
            validationMessageIcon=''
        >
            <Dropdown
                name={uid} 
                value={valueStr}
                selectedOptions={value}
                onOptionSelect={handleOnChange}
                multiselect
                placeholder="Nothing selected"
            >
                {
                    Object.values(options).sort((a,b) => a.rank - b.rank).map((answer) => 
                        (<Option value={answer.uid}>{answer.value}</Option>)
                    )
                }
            </Dropdown>
        </Field>
    );
}