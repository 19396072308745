import React from "react";
import { DismissRegular } from "@fluentui/react-icons";
import {
    MessageBar,
    MessageBarBody,
    MessageBarActions,
    Button

  } from "@fluentui/react-components";

export default function Message(props) {
    const {intent, message, onClose} = props;

    return (
        <MessageBar {...props} key={intent+message} intent={intent}>
            <MessageBarBody>
                {message}
            </MessageBarBody>
            <MessageBarActions
                containerAction={
                    <Button
                        onClick={onClose}
                        aria-label="dismiss"
                        appearance="transparent"
                        icon={<DismissRegular />}
                    />
                }
            />
        </MessageBar>
    );
}