import React, {useMemo} from "react";
import NoData from "../../blocks/NoData";
import { Divider, Title3, Subtitle2, Body1, makeStyles } from "@fluentui/react-components";
import { customTokens } from "../../utils/Theming";
import DOMPurify from "dompurify";

function filterEmptyExperience(work) {
  return typeof work.experience === 'string' && work.experience.length > 0;
}
function renderDate(startDate, endDate) {
  if (startDate && endDate) {
      return `${startDate} - ${endDate}`;
  } else if (startDate) {
      return `From ${startDate}`;
  } else if (endDate) {
      return `Until ${endDate}`;
  }
  return '';
}

const useStyles = makeStyles({
  infoListContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: customTokens.spacingVerticalMNudge,
  },
  infoListTitle: {
      marginBottom: customTokens.spacingVerticalMNudge,
      fontWeight: 'bold'
  }
});

export default function CandidateWorkExperience({data}) {
  const classes = useStyles();
 
  const workList = useMemo(() => {
    if (typeof data === 'undefined') {
      return [];
    }
    return Object.values(data).filter(filterEmptyExperience).sort((a, b) => a.rank - b.rank);
  }, [data]);

  const workDescription = (description) => ({__html: DOMPurify.sanitize(description)});
  
  return (
      <div>
          <Title3 block={true} className={classes.infoListTitle}>Work Experience</Title3>
          {workList.length > 0 ? workList.map(work => (
              <div className={classes.infoListContainer} key={work.uid}>
                {work.experience && <Title3 truncate wrap={false} block={true}>{work.experience}</Title3>}
                { (work.startDateFormatted || work.endDateFormatted) &&
                  <Subtitle2>{renderDate(work.startDateFormatted, work.endDateFormatted)}</Subtitle2>
                }
                { work.employerNameAndPlace && <Subtitle2>{work.employerNameAndPlace}</Subtitle2>}
                { work.description && 
                  <Body1>
                    <div dangerouslySetInnerHTML={workDescription(work.description)} />
                  </Body1>
                  }
                <Divider />
              </div>
          )): <NoData/>}
      </div>
  );
}