import React from "react";
import { Field, Input } from "@fluentui/react-components";

export default function TextField({uid, label, value, onChange, required, validation, type}) {
    const handleOnChange = (e, {value}) => {
        onChange(uid, value);
    };

    return (
        <Field
                label={label}
                id={uid}
                required={required}
                validationState={validation[0]}
                validationMessage={validation[1]}
                validationMessageIcon=''
            >
                <Input
                    name={uid}
                    type={type}
                    value={value}
                    onChange={handleOnChange}
                    autoComplete="off"
                />
            </Field>
    );
}