import React, {useRef, useState, useLayoutEffect, useEffect} from "react";
import CandidateTable from "./CandidateTable";
import CandidateWorkExperience from "./CandidateWorkExperiance";
import CandidateEducationHistory from "./CandidateEducationHistory";
import {
    Tab,
    TabList,
    makeResetStyles,
    makeStyles,
    shorthands
} from "@fluentui/react-components"; 
import {
    PersonInfo24Filled,
    PersonInfo24Regular,
    PersonAccounts24Filled,
    PersonAccounts24Regular,
    PersonRibbon24Filled,
    PersonRibbon24Regular,
    bundleIcon,
} from "@fluentui/react-icons";  
import { customTokens } from "../../utils/Theming";
import useSize from "@react-hook/size";


const PersonInfo24 = bundleIcon(PersonInfo24Filled, PersonInfo24Regular);
const PersonRibbon24 = bundleIcon(PersonRibbon24Filled, PersonRibbon24Regular);
const PersonAccounts24 = bundleIcon(PersonAccounts24Filled, PersonAccounts24Regular);

const useBaseClassName = makeResetStyles({
    ...shorthands.padding(customTokens.spacingVerticalMNudge, customTokens.spacingHorizontalL, 0)
});
const useStyles = makeStyles({
   firstTabsRow: {
        backgroundColor: customTokens.colorTabsBackground1,
   }
});

export default function CandidateInfo({candidate, onAddTabs}) {  
    const target = useRef(null);
    const baseClassName = useBaseClassName();
    const classes = useStyles();
    const [tab, setTab] = useState('tab1');
    const [width, ] = useSize(target);
    
    let tablistVertical = true;
    if (width > 470) {
        tablistVertical = false;
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [tab]);

    useLayoutEffect(() => {
        onAddTabs(
            <>
                <div className={classes.firstTabsRow}>
                    <TabList selectedValue={tab} onTabSelect={(e, {value}) => setTab(value)} vertical={tablistVertical}>
                        <Tab icon={<PersonInfo24 />} value="tab1">Candidate details</Tab>
                        <Tab icon={<PersonRibbon24 />} value="tab2">Education History</Tab>
                        <Tab icon={<PersonAccounts24 />} value="tab3">Work Experience</Tab>  
                    </TabList>
                </div>
            </>
        );

        return () => {
            onAddTabs(null);
        };
    }, [tab, tablistVertical, onAddTabs, classes.firstTabsRow]);
    
    const renderTab = () => {
        switch (tab) {
            case 'tab1':
                return <CandidateTable data={candidate} />;
            case 'tab2':
                return <CandidateEducationHistory data={candidate.EducationHistory} />;
            case 'tab3':
                return <CandidateWorkExperience data={candidate.EmploymentHistory} />;
            default:
                return null;
        }
    
    };

    return (
        <div className={baseClassName} ref={target}>
                {renderTab()}
        </div>
    );
}
