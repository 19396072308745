import React, { useRef, useEffect, useState, useCallback } from "react";
import {
    Tab,
    TabList,
    Spinner,
    makeStyles,
  } from "@fluentui/react-components"; 
import {
    DocumentEndnote24Filled,
    DocumentEndnote24Regular,
    DocumentText24Filled,
    DocumentText24Regular,
    Note24Filled,
    Note24Regular,
    bundleIcon,
  } from "@fluentui/react-icons";  
import ProcedureRow from "./ProcedureRow";
import ProcedureInfo from "./ProcedureInfo";
import MeetingFimForms from "../fim/MeetingFimForms";
import useSize from "@react-hook/size";
import Notes from "../note/Notes";
import { customTokens } from "../../utils/Theming";
import { useDispatch, useSelector } from "react-redux";
import { setProcedureOoid } from "./procedure-slice";
import { fetchProcedure } from "./procedure-slice";


const DocumentEndnote24 = bundleIcon(DocumentEndnote24Filled, DocumentEndnote24Regular);
const DocumentText24 = bundleIcon(DocumentText24Filled, DocumentText24Regular);
const Note24 = bundleIcon(Note24Filled, Note24Regular);

// const useBaseClassName = makeResetStyles({
// });
const useStyles = makeStyles({
   staticRow: {
        position: 'sticky',
        top: 0,
        zIndex: 1,
        backgroundColor: customTokens.colorNeutralBackground7
   }
});

export default function ProcedurePage({ooid, fim_forms}) {
    const {procedure} = useSelector(state => state.procedure);
    const dispatch = useDispatch();
    // const baseClassName = useBaseClassName();
    const classes = useStyles();
    const target = useRef(null);
    const [tab, setTab] = useState('tab2');
    const [additionalTabList, setAdditionalTabList] = useState(null);
    const [width, ] = useSize(target);

    let tablistSize = 'small';
    if (width > 275){
        tablistSize = 'medium'
    }
    let tabInfoText = 'Info';
    let tabNotesText = 'P - Notes';
    if (width > 375) {
        tabInfoText = 'Information';
        tabNotesText = 'Procedure Notes';
    
    }

    useEffect(() => {
        dispatch(setProcedureOoid(ooid));
        dispatch(fetchProcedure());
    }, [ooid, dispatch]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [tab]);

    const handleMoreTabs = useCallback((tabs) => {setAdditionalTabList(tabs);}, [setAdditionalTabList]);


    const renderTab = () => {
        switch (tab) {
            case 'tab1':
                return <MeetingFimForms items={fim_forms} entity="Reactie" ooid={ooid} onAddTabs={handleMoreTabs}></MeetingFimForms>;
            case 'tab2':
                return <ProcedureInfo ooid={ooid} procedure={procedure} onAddTabs={handleMoreTabs}></ProcedureInfo>;
            case 'tab3':
                return <Notes ooid={ooid} type='procedure'></Notes>;
            default:
                return null;
        }
    };

    let componentContent = <Spinner label="I am definitely loading..." />;
    if (procedure) {
        componentContent = (
            <>
                <div className={classes.staticRow}>
                    <ProcedureRow procedure={procedure} />
                    <TabList selectedValue={tab} onTabSelect={(e, {value}) => setTab(value)} size={tablistSize}>
                        {fim_forms && (<Tab icon={<DocumentEndnote24 />} value="tab1">{fim_forms.length >= 2 ? ('IForms') : ('IForm')}</Tab>)}
                        <Tab icon={<DocumentText24 />} value="tab2">{tabInfoText}</Tab>
                        <Tab icon={<Note24 />} value="tab3">{tabNotesText}</Tab>                
                    </TabList>
                    {additionalTabList}
                </div>
                {renderTab()}
            </>
        );
    }

    return (
        <div ref={target}>
            {componentContent}
        </div>
    );
}