import React, {useEffect} from "react";
import { Spinner } from '@fluentui/react/lib/Spinner';
import NoteItem from "./NoteItem";
import NoData from "../../blocks/NoData";
import { Divider, makeResetStyles, makeStyles, shorthands, Text, typographyStyles } from "@fluentui/react-components";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes } from "./note-slice";

const useBaseClassName = makeResetStyles({
    ...shorthands.padding('10px', '15px', 0)
});

const useStyles = makeStyles({
    title: typographyStyles.title3,
    noteContainer: {
        paddingTop: '15px',
    }
});

export default function Notes({ooid, type}) {
    const {notes, loading } = useSelector(state => state.note);
    const dispatch = useDispatch();
    const baseClassName = useBaseClassName();
    const classes = useStyles();

    useEffect(() => {
        dispatch(fetchNotes({ooid, type}));
    }, [ooid, type, dispatch]);
    if (loading) {
        return <Spinner label="I am definitely loading..." />;
    }
    console.log(notes);
    if(notes) {
        return (
            <div className={baseClassName}>
                <Text weight="bold" className={classes.title}>{type === 'candidate' ? 'Candidate': 'Procedure'} Notes</Text>
                <div className={classes.noteContainer}>
                    {
                        notes.length > 0 ? (
                            notes.map((note) => (
                                <div key={note.id}>
                                        <NoteItem note={note}></NoteItem>
                                        <Divider />
                                </div>
                            ))
                        ) : (
                            <NoData />
                        )
                    
                    }
                </div>
            </div>
        );
    }
}