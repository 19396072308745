import React, {useEffect, useLayoutEffect, useState, useRef} from "react";
import Ows from "../../utils/OwsHandler";
import CandidateWorkExperience from "../candidate/CandidateWorkExperiance";
import CandidateEducationHistory from "../candidate/CandidateEducationHistory";
import CandidateTable from "../candidate/CandidateTable";
import ProcedureRelationTable from "./ProcedureRelationTable";
import ProcedureVacancyTable from "./ProcedureVacancyTable";
import {
    Tab,
    TabList,
    makeResetStyles, 
    makeStyles,
    shorthands
  } from "@fluentui/react-components"; 
import {
    Person24Filled,
    Person24Regular,
    Briefcase24Filled,
    Briefcase24Regular,
    Patient24Filled,
    Patient24Regular,
    bundleIcon,
  } from "@fluentui/react-icons";
import { customTokens } from "../../utils/Theming";
import useSize from "@react-hook/size";

const Person24 = bundleIcon(Person24Filled, Person24Regular);
const Briefcase24 = bundleIcon(Briefcase24Filled, Briefcase24Regular);
const Patient24 = bundleIcon(Patient24Filled, Patient24Regular);

const useBaseClassName = makeResetStyles({
    ...shorthands.padding(customTokens.spacingVerticalMNudge, customTokens.spacingHorizontalL, 0)
});
const useStyles = makeStyles({
   firstTabsRow: {
        backgroundColor: customTokens.colorTabsBackground1,
   }
});

export default function ProcedureInfo({procedure, onAddTabs}) {
    const target = useRef(null);
    const baseClassName = useBaseClassName();
    const classes = useStyles();

    const [candidate, setCandidate] = useState(null);

    const [selectedValue, setSelectedValue] = useState('tab1');
    const [selectedSubValue, setSelectedSubValue] = useState('subtab1');
    const [width, ] = useSize(target);
    let tablistVertical = true;
    if (width > 450) {
        tablistVertical = false;
    }

    let tabExperienceText = 'Work exp.';
    let tabEducationText = 'Edu hist.';
    if (width > 400) {
        tabExperienceText = 'Work Experience';
        tabEducationText = 'Education History';
    }
    
    let tablistSize = 'small';
    if (width > 275){
        tablistSize = 'medium'
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [selectedValue, selectedSubValue]);

    useEffect(() => {
        (async () => {
            const {response: candidateDetail} = await Ows.handleRequest(
                'Otys.Services.CandidateService.getDetail',
                [
                    procedure.candidateUid,
                    {
                        EducationHistory: {
                            uid: true,
                            degree: true,
                            startDateFormatted: true,
                            endDateFormatted: true,
                            subjects: true,
                            institute: true,
                            diplomaDate: true,
                        },
                        EmploymentHistory: {
                            uid: true,
                            experience: true,
                            startDateFormatted: true,
                            endDateFormatted: true,
                            employerNameAndPlace: true,
                            description: true,
                        }
                    }
                ]
            );
            setCandidate(candidateDetail);
        })();
    }, [procedure.candidateUid]);

    useLayoutEffect(() => {
        onAddTabs(
            <>
                <div className={classes.firstTabsRow}>
                    <TabList selectedValue={selectedValue} onTabSelect={(e, {value}) => setSelectedValue(value)} vertical={tablistVertical}>
                        <Tab icon={<Person24 />} value="tab1">Candidate details</Tab>
                        <Tab icon={<Briefcase24 />} value="tab2">Vacancy details</Tab>
                        <Tab icon={<Patient24 />} value="tab3">Relation details</Tab>                
                    </TabList>
                </div>
                {selectedValue === 'tab1' && 
                    <div>
                        <TabList selectedValue={selectedSubValue} onTabSelect={(e, {value}) => setSelectedSubValue(value)} size={tablistSize}>
                            <Tab value="subtab1">Candidate info</Tab>
                            <Tab value="subtab2">{tabExperienceText}</Tab>
                            <Tab value="subtab3">{tabEducationText}</Tab>                
                        </TabList>
                    </div>
                }
            </>
        );

        return () => {
            onAddTabs(null);
        };
    }, [selectedValue, selectedSubValue, tablistVertical, tabEducationText, tabExperienceText, tablistSize, onAddTabs, classes.firstTabsRow]);

    const renderTab = () => {
        switch (selectedValue) {
            case 'tab1':
                switch (selectedSubValue) {
                    case 'subtab1':
                        return <CandidateTable data={procedure.Candidate} context='procedure'></CandidateTable>;
                    case 'subtab2':
                        return <CandidateWorkExperience data={candidate.EmploymentHistory}></CandidateWorkExperience>;
                    case 'subtab3':
                        return <CandidateEducationHistory data={candidate.EducationHistory}></CandidateEducationHistory>;
                    default:
                        return null;
                }
            case 'tab2':
                return <ProcedureVacancyTable ooid={procedure.vacancyUid}></ProcedureVacancyTable>;
            case 'tab3':
                return <ProcedureRelationTable ooid={procedure.Vacancy.relationId} ooidc={procedure.Vacancy.relationContactId}></ProcedureRelationTable>;
            default:
                return null;
        }
    
    };

    return (
        <div className={baseClassName} ref={target}>
                {renderTab()}
        </div>
    );
}  