import { teamsDarkTheme, teamsHighContrastTheme, teamsLightTheme, themeToTokensObject } from "@fluentui/react-components";

export const customLightTheme = {
    ...teamsLightTheme,
    colorBadgeBackground: '#d6d6d6',
    colorNeutralBackground3: "#eeeeee",
    colorNeutralBackground7: "#FCFCFC",
    colorTabsBackground1: '#FFFFFF',
    colorButtonBackground1: '#6264A7',
    colorDividerBackground1: '#605E5C',
};

export const customDarkTheme = {
    ...teamsDarkTheme,
    colorBadgeBackground: '#212932',
    colorNeutralBackground7: '#26303C',
    colorTabsBackground1: '#222932',
    colorButtonBackground1: '#6264A7',
    colorDividerBackground1: '#605E5C',
};

export const customHighContrastTheme = {
    ...teamsHighContrastTheme,
    colorBadgeBackground: '#212932',
    colorNeutralBackground7: '#26303C',
    colorTabsBackground1: '#222932',
    colorButtonBackground1: '#6264A7',
    colorDividerBackground1: '#605E5C',
};
//this is going to work because custome theme is injected in FluentProvider and tokens are using css variables
export const customTokens = themeToTokensObject(customLightTheme);