import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Ows from "../../utils/OwsHandler";

export const noteSlice = createSlice({
    name: "note",
    initialState: {
        notes: null,
        loading: false,
        error: null,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNotes.fulfilled, (state, action) => {
            state.loading = false;
            state.notes = action.payload;
        });
        builder.addCase(fetchNotes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
        builder.addCase(fetchNotes.pending, (state) => {
            state.loading = true;
        });
    }
});

export const fetchNotes = createAsyncThunk('note/fetch', async({ ooid, type }, { rejectWithValue }) => {
    try {
        let objectEntityId;
        switch (type) {
            case 'procedure':
                objectEntityId = 51;
                break;
            default:
            case 'candidate':
                objectEntityId = 2;
                break;
        }
        const { response } = await Ows.handleRequest(
            'Otys.Services.DossierService.getList', [{
                objectEntityId: objectEntityId,
                objectUid: ooid,
                restrictClass: ["Notes"],
                limit: 25,
                offset: 0
            }]
        );
        const detailRequest = response.map((note) => ({
            method: 'Otys.Services.NoticeService.getDetail',
            args: [
                note.id_rec,
                { text: 1 }
            ]
        }));
        const { response: details } = await Ows.handleMutliRequest(detailRequest);
        return response.map((note, index) => ({
            ...note,
            text: details[index].data.text,
        }));
    } catch (error) {
        return rejectWithValue(error);
    }
});