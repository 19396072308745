import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Ows from "../../utils/OwsHandler";

export const candidateSlice = createSlice({
    name: "candidate",
    initialState: {
        ooid: null,
        candidate: null,
        loading: false,
        error: null,
    },
    reducers: {
        setCandidateOoid: (state, action) => {
            state.ooid = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCandidate.fulfilled, (state, action) => {
            state.candidate = action.payload;
        });
        builder.addCase(fetchCandidate.rejected, (state, action) => {
            state.error = action.payload;
        });
        builder.addCase(fetchCandidate.pending, (state) => {
            state.loading = true;
        });
    }
})

export const fetchCandidate = createAsyncThunk('candidate/fetch', async(_, { getState, rejectWithValue }) => {
    const { candidate: { ooid } } = getState();
    if (!ooid) {
        return rejectWithValue('No ooid found');
    }
    try {
        const { response } = await Ows.handleRequest(
            'Otys.Services.CandidateService.getDetail', [
                ooid,
                {
                    uid: true,
                    Person: {
                        firstName: true,
                        infix: true,
                        lastName: true,
                        AddressPrimary: {
                            address: true,
                            city: true,
                            postcode: true,
                            country: true,
                        },
                        phoneMobile: true,
                        emailPrimary: true,
                        gender: true,
                        nationality: true,
                    },
                    status: true,
                    EducationHistory: {
                        uid: true,
                        degree: true,
                        startDateFormatted: true,
                        endDateFormatted: true,
                        subjects: true,
                        institute: true,
                        diplomaDate: true,
                    },
                    EmploymentHistory: {
                        uid: true,
                        experience: true,
                        startDateFormatted: true,
                        endDateFormatted: true,
                        employerNameAndPlace: true,
                        description: true,
                    },
                    profileImage: true,
                }
            ]
        );
        return response;
    } catch (error) {
        return rejectWithValue(error);
    }
});

export const { setCandidateOoid } = candidateSlice.actions;