import React, {useEffect, useState, useRef} from "react";
import Ows from "../../utils/OwsHandler";
import {
    TableBody,
    TableCell,
    TableRow,
    Table,
    makeStyles,
    Title3,
    Subtitle2,
    Body1,
    Spinner,
  } from "@fluentui/react-components";
import {
    BuildingTownhouse24Regular,
    PersonMail24Regular,
    Tag24Regular,
    PersonAdd24Regular,
    Location24Regular,
    PersonChat24Regular,
  } from "@fluentui/react-icons";  
import { safeAddress } from "../../utils/Helpers";
import { customTokens } from "../../utils/Theming";
import DOMPurify from "dompurify";
import useSize from "@react-hook/size";

const useStyles = makeStyles({
    label: {
        display: 'flex',
        alignItems: 'center',
    },
    labelContent: {
        display: 'inline-block',
        paddingLeft: '15px',
        position: 'relative',
        top: '-2px',
        color: customTokens.colorNeutralForeground2
    },
    vacancyText: {
        color: customTokens.colorNeutralForeground2,
    },
    vacancyTitle: {
        fontWeight: 'bold',
    },
    vacancyContainer: {
        marginTop: customTokens.spacingVerticalL,
    },
    vacancyBlock: {
        marginTop: customTokens.spacingVerticalMNudge,
    },
    fontSmall: {
        fontSize: customTokens.fontSizeBase300,
    }
});

export default function ProcedureVacancyTable({ooid}) {
    const classes = useStyles();
    const [vacancy, setVacancy] = useState(null);
    const target = useRef(null);
    const [width, ] = useSize(target);
    let tableSize = 'small';
    if (width > 300){
        tableSize = 'medium'
    }

    useEffect(() => {
        (async () => {
            const {response} = await Ows.handleRequest(
                'Otys.Services.VacancyService.getDetail',
                [
                    ooid,
                    {
                        title: true,
                        userEmail: true,
                        status: true,
                        locationAddress: true,
                        locationCity: true,
                        locationPostcode: true,
                        locationCountry: true,
                        applyCount: true,
                        user: true,
                        textField_description: true,
                        textField_requirements: true,
                    }
                ]
            );
            setVacancy(response);
        })();
    }, [ooid]);

    const tableContent = [];
    if (vacancy) {
        if (vacancy.title) {
            tableContent.push({
                key: 'title',
                icon: <BuildingTownhouse24Regular />,
                label: vacancy.title
            });
        }
        if (vacancy.userEmail) {
            tableContent.push({
                key: 'userEmail',
                icon: <PersonMail24Regular />,
                label: vacancy.userEmail
            });
        }
        if (vacancy.status) {
            tableContent.push({
                key: 'status',
                icon: <Tag24Regular />,
                label: vacancy.status
            });
        }
        if (vacancy.locationAddress) {
            const location = safeAddress(', ', vacancy.locationAddress, [vacancy.locationCity, vacancy.locationPostcode], vacancy.locationCountry);
            if (location) {
                tableContent.push({
                    key: 'location',
                    icon: <Location24Regular />,
                    label: location
                });
            }
        }
        if (vacancy.applyCount) {
            tableContent.push({
                key: 'applyCount',
                icon: <PersonAdd24Regular />,
                label: vacancy.applyCount + ' candidates needed'
            });
        }
        if (vacancy.user) {
            tableContent.push({
                key: 'user',
                icon: <PersonChat24Regular />,
                label: vacancy.user
            });
        }
    }
    let componentContent = <Spinner label="I am definitely loading..." />;

    if(vacancy) {
        const vacDescription = () => ({__html: DOMPurify.sanitize(vacancy.textField_description.text)});
        const vacRequirements = () => ({__html: DOMPurify.sanitize(vacancy.textField_requirements.text)});
        componentContent =  (
            <>
                <Table size={tableSize} className={width > 275 ? '': classes.fontSmall}>
                    <TableBody>
                        {tableContent.map((item) => (
                            <TableRow key={item.key}>
                                <TableCell className={classes.label}>
                                    {item.icon}
                                    <div className={classes.labelContent}>
                                        {item.label}
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}

                    </TableBody>
                </Table> 
                <div className={classes.vacancyContainer}>
                    <Title3 className={classes.vacancyTitle}>Vacancy text</Title3>
                    <div className={classes.vacancyBlock}>
                        <Subtitle2>{vacancy.textField_description.title}</Subtitle2>
                        <Body1 className={classes.vacancyText}>
                            {<div dangerouslySetInnerHTML={vacDescription()} />}
                        </Body1>
                    </div>
                    <div className={classes.vacancyBlock}>
                        <Subtitle2>{vacancy.textField_requirements.title}</Subtitle2>
                        <Body1 className={classes.vacancyText}>
                            {<div dangerouslySetInnerHTML={vacRequirements()} />}
                        </Body1>
                    </div>
                </div>
            </>
        );
    }
    return (
        <div ref={target}>
            {componentContent}
        </div>
    );
}