import React, {useState} from "react";

export default function Logo({width: pWidth}) {

    const [width, setWidth] = useState(50);
    const [height, setHeight] = useState(50);

    if(pWidth && pWidth !== width) {
        const r = height / width;
        setWidth(pWidth);
        setHeight(pWidth * r);
    }

    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="25" fill="#FFD300"/>
            <path d="M41.001 18.3701C41.0123 17.9006 40.9144 17.435 40.7151 17.0098C40.5158 16.5846 40.2204 16.2115 39.8523 15.9198C39.5311 15.7053 39.1695 15.5586 38.7896 15.4886C38.4097 15.4187 38.0196 15.427 37.643 15.513C37.2724 15.5793 36.9195 15.7214 36.6065 15.9306C36.2935 16.1398 36.0272 16.4115 35.8243 16.7286C34.263 19.3454 30.1326 25.4309 25.5101 25.4309C20.8723 25.4309 16.7399 19.3445 15.1786 16.7286C14.976 16.4112 14.7098 16.1393 14.3968 15.9301C14.0837 15.7209 13.7307 15.5789 13.3599 15.513C12.9823 15.4264 12.591 15.4178 12.2099 15.4877C11.8289 15.5577 11.4661 15.7047 11.1439 15.9198C10.5668 16.3956 10.1768 17.0603 10.043 17.7961C9.9091 18.5319 10.04 19.2913 10.4126 19.9398C11.8554 22.6919 14.0237 24.9968 16.6827 26.6049C19.3417 28.2131 22.3901 29.0632 25.4976 29.0632C28.6052 29.0632 31.6535 28.2131 34.3125 26.6049C36.9715 24.9968 39.1398 22.6919 40.5826 19.9398C40.8605 19.4636 41.005 18.9214 41.001 18.3701Z" fill="white"/>
            <path d="M25.5005 21.9201C27.4116 21.9201 28.9609 20.371 28.9609 18.46C28.9609 16.5491 27.4116 15 25.5005 15C23.5894 15 22.0401 16.5491 22.0401 18.46C22.0401 20.371 23.5894 21.9201 25.5005 21.9201Z" fill="white"/>
            <path d="M25.5005 38.001C27.4116 38.001 28.9609 36.4519 28.9609 34.5409C28.9609 32.63 27.4116 31.0809 25.5005 31.0809C23.5894 31.0809 22.0401 32.63 22.0401 34.5409C22.0401 36.4519 23.5894 38.001 25.5005 38.001Z" fill="white"/>
        </svg>
    );
}