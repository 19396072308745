export function safeAddress(separator = ', ', ...args) {
    // argument could be null, string or arrat of null/strings
    // filter out all null values and join the rest with ', '
    let ret = '';
    for (const arg of args) {
        if (arg === null || arg === undefined) {
            continue;
        }
        if (Array.isArray(arg)) {
            ret += safeAddress(' ', ...arg) + separator;
        } else {
            ret += arg + separator;
        }
    }
    if (ret.length > 0) {
        ret = ret.slice(0, -separator.length);
    }
    return ret;
}