import React, {useState, useEffect} from "react";
import Ows from "../../../utils/OwsHandler";
import {Field, Input} from "@fluentui/react-components";
import parsePhoneNumberFromString from 'libphonenumber-js';


export default function PhoneField({uid, label, value, onChange, required, validation}) {
    const [countryCode, setCountryCode] = useState('');
    const [shouldFormat, setShouldFormat] = useState(false);
    useEffect(() => {
        (async () => {
            const {response} = await Ows.handleRequest('Otys.Services.SettingsService.get', [
                {bo_client: ['country_code', 'no_phone_formatting']},
                null
            ]);
            setCountryCode(response.bo_client.country_code);
            setShouldFormat(!response.no_phone_formatting);
        })();
    }, []);

    const handleOnChange = (e, {value}) => {
        onChange(uid, value);
    };

    const handleFocusOut = (e) => {
        if (shouldFormat) {
            const phone = e.target.value;
            const number = formatPhone(phone);
            onChange(uid, number);
        }
    };

    const formatPhone = (phone) => {
        if (phone.length === 0) {
            return '';
        }
        const phoneBackup = phone;
        if(phone.slice(0,2) == "00"){
            phone = phone.slice(2);
        }
        const number = parsePhoneNumberFromString(phone, {defaultCallingCode: countryCode});
    
        if (number) {
            return number.formatInternational();
        }
        
        return phoneBackup;
    }

    return (
        <Field
                label={label}
                id={uid}
                required={required}
                validationState={validation[0]}
                validationMessage={validation[1]}
                validationMessageIcon=''
            >
                <Input type='tel' name={uid} value={value} onBlur={handleFocusOut} onChange={handleOnChange} autoComplete="off" />
            </Field>
        
    );
}